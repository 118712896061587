import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
    <title>Logo</title>
    <g>
      <g id="B">
        <path
          d="M 430.00,466.00
C 430.00,466.00 423.34,485.00 423.34,485.00
  423.34,485.00 414.15,515.00 414.15,515.00
  407.84,538.32 400.04,574.14 400.00,598.00
  400.00,598.00 400.00,620.00 400.00,620.00
  400.01,627.53 401.86,642.88 404.04,650.00
  412.05,676.25 438.84,690.39 465.00,690.99
  498.68,691.75 539.58,669.81 564.00,647.83
  597.90,617.32 621.72,577.86 638.99,536.00
  638.99,536.00 653.00,498.00 653.00,498.00
  653.00,498.00 660.14,476.85 660.14,476.85
  665.72,467.27 674.29,475.37 682.00,476.85
  691.96,478.35 699.39,469.85 701.83,461.00
  706.78,443.06 681.84,446.49 674.85,435.90
  670.43,429.19 675.89,412.68 676.00,404.00
  676.13,392.76 676.88,383.97 670.45,374.00
  657.67,354.16 632.83,353.67 618.39,372.00
  611.48,380.77 610.88,390.38 611.00,401.00
  611.11,409.85 613.75,422.18 617.89,430.00
  620.40,434.74 626.88,442.20 627.70,446.00
  628.57,450.11 626.00,458.74 624.85,463.00
  624.85,463.00 611.40,503.00 611.40,503.00
  601.22,528.65 589.37,552.05 574.00,575.00
  557.33,599.88 530.17,627.99 502.00,639.40
  494.18,642.56 488.52,644.18 480.00,643.99
  458.85,643.50 452.21,622.94 452.00,605.00
  451.83,590.58 455.15,572.27 458.00,558.00
  467.76,509.17 480.68,480.75 503.26,437.00
  503.26,437.00 528.42,393.00 528.42,393.00
  530.79,389.18 539.92,375.35 540.54,372.00
  541.42,367.26 537.93,363.55 534.00,361.56
  528.65,358.84 512.02,356.74 506.00,357.09
  479.64,358.63 463.66,390.16 453.25,411.00
  453.25,411.00 445.36,426.96 445.36,426.96
  442.43,430.85 437.25,433.51 433.00,435.82
  415.57,445.29 401.09,444.18 383.00,437.00
  383.00,437.00 392.28,411.00 392.28,411.00
  400.92,382.02 403.24,349.13 385.64,323.00
  381.85,317.37 377.12,311.75 372.00,307.29
  358.26,295.32 322.10,279.13 304.00,266.65
  291.54,258.06 280.02,245.89 279.09,230.00
  278.48,219.52 284.30,205.60 290.00,197.00
  304.40,175.28 328.03,163.31 354.00,163.00
  369.78,162.82 379.35,163.12 394.00,170.26
  401.70,174.01 409.15,179.40 413.47,187.00
  417.57,194.22 415.85,202.92 425.00,204.48
  435.16,206.21 443.47,194.72 445.47,186.00
  446.10,183.28 446.03,179.82 446.00,177.00
  445.57,139.39 398.76,125.36 368.00,125.00
  319.81,124.44 270.01,148.50 245.72,191.00
  238.04,204.44 232.02,220.36 232.00,236.00
  232.00,236.00 232.00,246.00 232.00,246.00
  232.30,271.53 249.39,294.38 270.00,307.92
  270.00,307.92 309.00,329.00 309.00,329.00
  327.48,340.12 348.72,358.67 349.00,382.00
  349.16,396.18 345.01,408.40 338.75,421.00
  336.01,426.52 333.57,432.39 327.00,434.16
  323.14,435.20 317.86,433.34 314.00,432.37
  314.00,432.37 287.00,425.63 287.00,425.63
  254.23,417.43 221.68,409.20 188.00,405.83
  170.93,404.13 150.15,406.39 137.44,419.30
  132.35,424.47 129.88,429.21 127.67,436.00
  113.61,479.23 155.46,507.43 192.00,516.53
  229.00,525.73 269.79,525.82 305.00,509.69
  318.22,503.64 330.87,496.11 342.00,486.71
  346.09,483.25 359.71,469.99 363.00,468.74
  367.14,467.16 379.91,469.99 386.00,470.00
  406.12,470.03 410.08,469.75 430.00,466.00 Z
M 178.00,436.33
C 185.68,435.50 202.68,439.36 211.00,441.00
  211.00,441.00 273.00,454.21 273.00,454.21
  273.00,454.21 295.00,459.70 295.00,459.70
  297.10,460.46 300.60,461.72 301.18,464.17
  301.73,466.53 299.59,468.34 297.89,469.53
  295.00,471.56 287.47,474.94 284.00,476.42
  260.20,486.60 245.47,488.29 220.00,488.00
  203.48,487.80 179.78,479.84 168.33,467.71
  158.95,457.78 161.24,440.35 178.00,436.33 Z"
          fill="#64FFDA"
        />
      </g>
      <path
        stroke="#64FFDA"
        fill="#64FFDA"
        d="M 410.00,43.21
           C 410.00,43.21 399.00,44.09 399.00,44.09
             399.00,44.09 380.00,46.28 380.00,46.28
             361.70,48.69 339.62,53.55 322.00,59.02
             222.47,89.96 139.89,161.08 96.31,256.00
             45.16,367.39 50.89,493.71 112.00,600.00
             125.79,623.98 142.09,645.76 160.91,666.00
             160.91,666.00 170.00,675.99 170.00,675.99
             207.71,713.22 253.49,741.75 303.00,760.42
             336.22,772.95 370.68,779.67 406.00,782.09
             406.00,782.09 416.00,783.00 416.00,783.00
             416.00,783.00 446.00,783.00 446.00,783.00
             446.00,783.00 458.00,782.09 458.00,782.09
             511.26,778.45 561.93,764.15 609.00,738.69
             650.24,716.39 685.46,687.79 715.73,652.00
             756.83,603.40 785.86,540.71 796.75,478.00
             801.63,449.91 802.04,427.23 802.00,399.00
             801.98,385.69 798.99,360.37 796.58,347.00
             788.03,299.63 770.86,255.86 745.42,215.00
             733.09,195.18 718.97,177.08 703.09,160.00
             703.09,160.00 694.00,150.01 694.00,150.01
             653.09,109.62 602.48,79.16 548.00,61.00
             518.72,51.24 488.69,46.01 458.00,43.91
             458.00,43.91 446.00,43.21 446.00,43.21
             446.00,43.21 410.00,43.21 410.00,43.21 Z
           M 417.00,64.28
           C 443.31,61.84 488.10,67.65 514.00,74.13
             575.54,89.51 633.88,121.06 678.91,166.09
             733.22,220.40 769.05,294.84 778.15,371.00
             778.15,371.00 780.09,393.00 780.09,393.00
             780.09,393.00 781.00,403.00 781.00,403.00
             781.91,481.44 760.39,552.91 714.71,617.00
             704.00,632.02 691.96,646.86 678.91,659.91
             651.86,686.96 619.15,709.21 585.00,726.25
             534.49,751.44 478.20,762.65 422.00,762.00
             422.00,762.00 412.00,761.09 412.00,761.09
             377.88,758.75 344.12,752.54 312.00,740.42
             265.18,722.76 220.57,695.39 185.09,659.91
             130.78,605.60 94.95,531.16 85.85,455.00
             85.85,455.00 83.91,433.00 83.91,433.00
             83.91,433.00 83.00,423.00 83.00,423.00
             82.28,361.39 94.94,305.88 123.26,251.00
             138.58,221.30 161.50,189.68 185.09,166.09
             222.22,128.96 273.90,97.36 324.00,81.34
             344.14,74.91 365.03,70.04 386.00,67.28
             386.00,67.28 405.00,65.09 405.00,65.09
             405.00,65.09 417.00,64.28 417.00,64.28 Z"
      />
      />
    </g>
  </svg>
);

export default IconLogo;
